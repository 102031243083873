.info-title{
    font-size: 24px;
}

.info-row{
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding: 5px 0;
    width: 50%;
    font-size: 18px;
}

@media (max-width:768px)  { 
    .info-row{
        width: 100%;
    }
}