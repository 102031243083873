@import "styles/base";

@font-face {
  font-family: "CustomFont";
  src: url("./resources/fonts/DMSerifDisplay-Regular.ttf");
}

@font-face {
  font-family: "TitleFont";
  src: url("./resources/fonts/CinzelDecorative-Regular.ttf");
}

@font-face {
  font-family: "MavenPro";
  src: url("./resources/fonts/MavenPro-Regular.ttf");
}

@font-face {
  font-family: "Merriweather";
  src: url("./resources/fonts/Merriweather-Regular.ttf");
}

@font-face {
  font-family: "Archivo-Black";
  src: url("./resources/fonts/ArchivoBlack-Regular.ttf");
}

@font-face {
  font-family: "Archivo-Narrow";
  src: url("./resources/fonts/ArchivoNarrow-Regular.ttf");
}

body,
html,
#root {
  margin: $no-margin;
  padding: $no-padding;
  scroll-behavior: smooth;
}

p {
  margin: $no-margin !important;
  padding: $no-padding !important;
  font-family: Merriweather, sans-serif;
  font-size: 17.5px;
  line-height: 1.8;
  letter-spacing: 0.4px;
}

div {
  font-family: Archivo-Narrow, sans-serif;
}

body {
  overflow-x: hidden;
  // background-image: url("./resources/images/pc-1.jpg");
  // background-size: cover;
  // background-attachment: fixed;
  // overflow-x: hidden;
}

b {
  font-weight: bold !important;
}

ul {
  margin-top: 10px !important;
}

li {
  font-family: Merriweather, sans-serif;
  line-height: 2;
  font-size: 17.5px;
  letter-spacing: 0.4px;
  list-style-type: square;
}

mark {
  background-color: #fff3b6 !important;
}

.bg-white {
  background-color: #ffffff;
}

.f-12 {
  font-size: 12px;
}

.gray {
  color: $gray;
}

.mt-300 {
  margin-top: 300px;
}

@media (max-width: 768px) {
  /* smartphones, Android phones, landscape iPhone */
  p,
  li {
    font-size: 14px;
  }
}
